.btn-facebook {
    --background: #4a68ad;
}

.btn-google {
    --background: #DB4437;
}

.photo-upload {
    .camera {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        border: 1px solid var(--global-border-color);
        color: var(--global-border-color);
        height: $camera-size;

        i {
            font-size: 3rem;
        }
    }

    input {
        position: absolute;
        width: calc(100% - $main-margin * 2);
        opacity: 0;
        height: $camera-size;
        z-index: 1;
        cursor: pointer;
    }
}

.photo {
    display: flex;

    img {
        border-radius: 4px;
        border: 1px solid var(--global-border-color);
    }
}

.list-warning,
.list-error {
    font-size: .75rem;
    display: flex;
    align-items: center;

    .icon {
        margin-right: .125rem;
        min-width: .75rem;
    }
}

.list-warning {
    color: var(--ion-color-warning);
}

.list-error {
    color: var(--ion-color-danger);
}

#coupler-size-alert {
    .alert-checkbox-group {
        border: none;

        .alert-checkbox {
            height: 4rem;

            .alert-checkbox-label {
                text-overflow: unset;
                white-space: normal;
            }
        }
    }
}

.ellipsis {
    position: relative;
    width: 100%;

    &:before {
        content: '&nbsp;';
        visibility: hidden;
    }

    > span {
        position: absolute;
        left: 0;
        right: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.notifications {
    margin-right: -.5rem !important;

    .count {
        position: absolute;
        margin: -1.25rem -1.25rem 0 0;
        background: var(--ion-toolbar-background, var(--ion-background-color));
        border: 1px solid var(--ion-toolbar-color, var(--ion-text-color));
        border-radius: 50%;
        width: 1.15rem;
        aspect-ratio: 1/1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .65rem;
    }

    &.ios {
        margin-right: -.25rem !important;

        .count {
            margin-top: -.85rem;
        }
    }
}

.link {
    color: var(--ion-color-primary);
    text-decoration: underline;
    cursor: pointer;
}

.invisible {
    visibility: hidden;
}

.install {
    &.md {
        --max-width: 20rem;

        .install-close-btn {
            .alert-button-inner {
                min-width: 2.75rem;
                justify-content: center;
            }
        }
    }

    .alert-title {
        margin: 1rem 0;
    }

    .alert-message {
        max-height: 22rem;
        padding-top: .5rem;

        > img {
            display: flex;
            margin: 0 auto;
            border-radius: .5rem;
        }

        ol {
            padding-left: 1rem;
            margin: 0;

            li {
                margin: .5rem 0;

                .tap {
                    display: flex;
                    align-items: center;
                    line-height: 2rem;

                    i {
                        font-size: 2rem;
                        color: var(--ion-text-color);
                    }

                    img {
                        height: 2rem;
                        width: 2rem;
                        border-radius: .25rem;
                        margin: 0 .5rem;
                    }

                    .android-icon {
                        margin: 0 0.25rem;
                        color: var(--ion-text-color);
                    }

                    &.ios {
                        i.share-icon {
                            color: #4693ff;
                        }
                    }
                }
            }
        }
    }

    &.ios {
        .message {
            display: flex;
            justify-content: center;
        }
    }
}

.flip {
    transform: rotate(180deg);
}

.map-instructions {
    .pins {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        margin-bottom: 1rem;

        > div {
            display: flex;
            align-items: center;
            gap: .5rem;

            img {
                height: 2rem;
            }
        }
    }

    .drivers-icon {
        height: 1rem;
        margin: 0 .15rem -.15rem .15rem;
    }

    &.ios {
        .pins {
            width: 4.5rem;
            margin: 0 auto 1rem auto;
        }
    }
}

.success {
    color: var(--ion-color-success);
}

@media screen and (min-width: $sm-min-width) {
    .photo-upload {
        input {
            width: calc(100% - $main-margin-sm * 2);
        }
    }
}
