@import '../base/variables';

@media screen and (min-width: $desktop-min-width) {
    ion-app {
        width: var(--desktop-width);
        height: var(--laptop-height);
        margin: auto;
        box-shadow: 0 0 4rem rgba(0, 0, 0, .5);
    }

    ion-modal {
        --height: 100%;
    }

    .pac-container {
        width: calc(var(--desktop-width) - 4rem) !important;
        left: calc(50% - (var(--desktop-width) - 4rem) / 2) !important;
    }
}

@media screen and (min-width: $desktop-min-width) and (min-height: $desktop-min-height) {
    ion-app {
        height: var(--desktop-height);
    }
}
