.map-icon {
    height: 2.5rem;

    &.drop {
        opacity: 0;
        animation: drop .3s linear forwards .5s;
    }

    &.bounce {
        animation: bounce .8s ease-in-out infinite;
    }
}

@keyframes drop {
    0% {
        transform: translateY(-12.5rem) scaleY(.9);
    }
    5% {
        opacity: .7;
    }
    50% {
        transform: translateY(0) scaleY(1);
        opacity: 1;
    }
    65% {
        transform: translateY(-1rem) scaleY(.9);
        opacity: 1;
    }
    75% {
        transform: translateY(-1.5rem) scaleY(.9);
        opacity: 1;
    }
    100% {
        transform: translateY(0) scaleY(1);
        opacity: 1;
    }
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-1rem);
    }
}
