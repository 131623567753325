@import '../base/variables';

.pac-container {
    width: calc(100% - $main-margin * 2) !important;
    left: $main-margin !important;
    background: var(--ion-color-step-100);
    border: none;

    .pac-item {
        color: var(--ion-text-color);
        padding: 0 1rem;
        border: none;

        .pac-icon {
            color: inherit;
            border: none;
        }

        .pac-item-query {
            color: inherit;
        }

        &.pac-item-selected,
        &.pac-item:hover {
            background: rgba(255, 255, 255, .08);
        }
    }
}

.pac-logo:after {
    display: none;
}

@media screen and (min-width: $sm-min-width) {
    .pac-container {
        width: calc(100% - $main-margin-sm * 2) !important;
        left: $main-margin-sm !important;
    }
}
