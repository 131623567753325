.input-group-prepend,
.input-group-append {
    display: flex;
    align-items: center;
}

.input-group {
    &.invalid {
        border-color: var(--ion-color-danger);
        color: var(--ion-color-danger);
    }
}

mat-form-field {
    width: 100%;

    .mat-mdc-form-field-icon-prefix {
        .material-symbols-outlined {
            padding: 0 .75rem;
            margin-right: 0;
        }
    }

    .mat-mdc-form-field-icon-suffix {
        margin-right: 1rem;
    }

    .mat-mdc-form-field-infix {
        height: 3rem; // Fix for iOS for the input fields seem to resize when on focus
    }

    &.textarea {
        .mat-mdc-form-field-infix {
            height: auto;
        }
    }

    &:not(.no-suffix) {
        .mdc-notched-outline__leading {
            width: 2.5rem !important;
        }

        .mat-mdc-form-field-icon-prefix {
            margin-right: -.5rem;
        }

        label {
            margin-left: 1rem;

            &.mdc-floating-label--float-above {
                margin-left: 0;
            }
        }

        .mat-mdc-form-field-subscript-wrapper {
            height: 1rem;
        }
    }

    &.mat-form-field-disabled {
        i {
            color: var(--global-border-color);
        }
    }

    &.mat-form-field-invalid {
        i {
            color: var(--ion-color-danger);
        }
    }

    &.autocomplete {
        .mat-mdc-form-field-infix {
            padding: 0 !important;
        }

        input {
            padding: 1rem 0;
        }
    }
}

ion-button {
    margin: 0;
}

form {
    ion-button[type=submit] {
        margin-top: 1rem;
    }
}

.form-field-warning,
.form-field-error {
    margin-top: -.75rem;
    font-size: .75rem;
    margin-bottom: .75rem;
    display: flex;
    align-items: center;

    .icon {
        margin-right: .125rem;
    }
}

.form-field-warning {
    color: var(--ion-color-warning);
}

.form-field-error {
    color: var(--ion-color-danger);
}

.form-date-picker {
    --width: 16rem;

    &.time {
        ion-datetime.ios {
            height: 25.25rem;
        }
    }
}

.form-date-picker-wheel {
    --width: 22rem;
}

textarea {
    resize: none !important;
}
