:root {
  --header-height: 3.5rem;
  --footer-height: 7.0625rem;
  --header-buttons-width: 5.5rem;
  --logo-padding-right: 3.25rem;
  --slider-bar-color: var(--ion-color-primary);
  --slider-bar-opacity: .5;
  --slider-knob-color: var(--ion-color-primary);
  --slider-shadow: none;
  --slider-size: 1;
  --global-border-color: rgba(255, 255, 255, .38);
  --form-label-color: rgba(255, 255, 255, .6);
  --menu-icon-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), .54);
  --button-height: 2.25rem;
  --laptop-height: 45rem;
  --desktop-width: 25rem;
  --desktop-height: 50rem;
  --desktop-footer-height: 6.8575rem;
  --map-details-arrow-padding: 0rem;
  --trailer-map-inputs-translate-y-extra: 0rem;
  --trailer-map-inputs-in-progress-translate-y-extra: 0rem;
  --trailer-map-inputs-completed-translate-y-extra: 0rem;
  --trailer-map-driver-details-height: 16.5rem;
  --trailer-map-driver-details-translate-y: calc(var(--trailer-map-driver-details-height) - 2rem + .15rem);
  --driver-map-tow-details-height: 11rem;
  --driver-map-tow-details-translate-y: calc(var(--driver-map-tow-details-height) - 2rem + .15rem);
}
:root .ios {
  --header-height: 7rem;
  --header-buttons-width: 4.8755rem;
  --logo-padding-right: 3.909375rem;
  --slider-bar-color: var(--ion-color-light-contrast);
  --slider-bar-opacity: .1;
  --slider-knob-color: var(--ion-color-dark-contrast);
  --slider-shadow: 0 3px 1px rgb(0 0 0 / 10%), 0 4px 8px rgb(0 0 0 / 13%), 0 0 0 1px rgb(0 0 0 / 2%);
  --slider-size: 1.5;
  --menu-icon-color: var(--ion-text-color);
  --button-height: 3.099375rem;
  --map-details-arrow-padding: 1rem;
  --trailer-map-inputs-translate-y-extra: .25rem;
  --trailer-map-inputs-in-progress-translate-y-extra: -1rem;
  --trailer-map-inputs-completed-translate-y-extra: -1rem;
  --trailer-map-driver-details-height: calc(16.5rem + var(--map-details-arrow-padding));
  --trailer-map-driver-details-translate-y: calc(var(--trailer-map-driver-details-height) - 2rem + .15rem - var(--map-details-arrow-padding));
  --driver-map-tow-details-height: calc(11.5rem + var(--map-details-arrow-padding));
  --driver-map-tow-details-translate-y: calc(var(--driver-map-tow-details-height) - 2rem + .15rem - var(--map-details-arrow-padding));
}

:root {
  --header-height: 3.5rem;
  --footer-height: 7.0625rem;
  --header-buttons-width: 5.5rem;
  --logo-padding-right: 3.25rem;
  --slider-bar-color: var(--ion-color-primary);
  --slider-bar-opacity: .5;
  --slider-knob-color: var(--ion-color-primary);
  --slider-shadow: none;
  --slider-size: 1;
  --global-border-color: rgba(255, 255, 255, .38);
  --form-label-color: rgba(255, 255, 255, .6);
  --menu-icon-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), .54);
  --button-height: 2.25rem;
  --laptop-height: 45rem;
  --desktop-width: 25rem;
  --desktop-height: 50rem;
  --desktop-footer-height: 6.8575rem;
  --map-details-arrow-padding: 0rem;
  --trailer-map-inputs-translate-y-extra: 0rem;
  --trailer-map-inputs-in-progress-translate-y-extra: 0rem;
  --trailer-map-inputs-completed-translate-y-extra: 0rem;
  --trailer-map-driver-details-height: 16.5rem;
  --trailer-map-driver-details-translate-y: calc(var(--trailer-map-driver-details-height) - 2rem + .15rem);
  --driver-map-tow-details-height: 11rem;
  --driver-map-tow-details-translate-y: calc(var(--driver-map-tow-details-height) - 2rem + .15rem);
}
:root .ios {
  --header-height: 7rem;
  --header-buttons-width: 4.8755rem;
  --logo-padding-right: 3.909375rem;
  --slider-bar-color: var(--ion-color-light-contrast);
  --slider-bar-opacity: .1;
  --slider-knob-color: var(--ion-color-dark-contrast);
  --slider-shadow: 0 3px 1px rgb(0 0 0 / 10%), 0 4px 8px rgb(0 0 0 / 13%), 0 0 0 1px rgb(0 0 0 / 2%);
  --slider-size: 1.5;
  --menu-icon-color: var(--ion-text-color);
  --button-height: 3.099375rem;
  --map-details-arrow-padding: 1rem;
  --trailer-map-inputs-translate-y-extra: .25rem;
  --trailer-map-inputs-in-progress-translate-y-extra: -1rem;
  --trailer-map-inputs-completed-translate-y-extra: -1rem;
  --trailer-map-driver-details-height: calc(16.5rem + var(--map-details-arrow-padding));
  --trailer-map-driver-details-translate-y: calc(var(--trailer-map-driver-details-height) - 2rem + .15rem - var(--map-details-arrow-padding));
  --driver-map-tow-details-height: calc(11.5rem + var(--map-details-arrow-padding));
  --driver-map-tow-details-translate-y: calc(var(--driver-map-tow-details-height) - 2rem + .15rem - var(--map-details-arrow-padding));
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

main {
  margin: 1rem;
  min-height: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
}
main.center {
  justify-content: center;
}

section ion-list {
  padding: 0 !important;
}
section ion-item {
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  --padding-start: 0;
  --padding-end: 0;
}

p, .p {
  line-height: 1.5rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1rem var(--ion-background-color) inset !important;
  -webkit-text-fill-color: var(--ion-text-color) !important;
}

@media screen and (min-width: 412px) {
  main {
    margin: 2rem;
    min-height: calc(100% - 4rem);
  }
}
@media screen and (orientation: landscape) {
  html.ios body {
    width: 100vh;
    height: 100vw;
    transform-origin: 0 0;
  }
  html.ios body.rotate-right {
    transform: rotate(90deg) translateY(-100%);
    margin-left: calc(100vw - 100vh);
  }
  html.ios body.rotate-left {
    transform: rotate(-90deg) translateX(-100%);
  }
  html.ios ion-app {
    height: 100vw;
  }
}
.input-group-prepend,
.input-group-append {
  display: flex;
  align-items: center;
}

.input-group.invalid {
  border-color: var(--ion-color-danger);
  color: var(--ion-color-danger);
}

mat-form-field {
  width: 100%;
}
mat-form-field .mat-mdc-form-field-icon-prefix .material-symbols-outlined {
  padding: 0 0.75rem;
  margin-right: 0;
}
mat-form-field .mat-mdc-form-field-icon-suffix {
  margin-right: 1rem;
}
mat-form-field .mat-mdc-form-field-infix {
  height: 3rem;
}
mat-form-field.textarea .mat-mdc-form-field-infix {
  height: auto;
}
mat-form-field:not(.no-suffix) .mdc-notched-outline__leading {
  width: 2.5rem !important;
}
mat-form-field:not(.no-suffix) .mat-mdc-form-field-icon-prefix {
  margin-right: -0.5rem;
}
mat-form-field:not(.no-suffix) label {
  margin-left: 1rem;
}
mat-form-field:not(.no-suffix) label.mdc-floating-label--float-above {
  margin-left: 0;
}
mat-form-field:not(.no-suffix) .mat-mdc-form-field-subscript-wrapper {
  height: 1rem;
}
mat-form-field.mat-form-field-disabled i {
  color: var(--global-border-color);
}
mat-form-field.mat-form-field-invalid i {
  color: var(--ion-color-danger);
}
mat-form-field.autocomplete .mat-mdc-form-field-infix {
  padding: 0 !important;
}
mat-form-field.autocomplete input {
  padding: 1rem 0;
}

ion-button {
  margin: 0;
}

form ion-button[type=submit] {
  margin-top: 1rem;
}

.form-field-warning,
.form-field-error {
  margin-top: -0.75rem;
  font-size: 0.75rem;
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
}
.form-field-warning .icon,
.form-field-error .icon {
  margin-right: 0.125rem;
}

.form-field-warning {
  color: var(--ion-color-warning);
}

.form-field-error {
  color: var(--ion-color-danger);
}

.form-date-picker {
  --width: 16rem;
}
.form-date-picker.time ion-datetime.ios {
  height: 25.25rem;
}

.form-date-picker-wheel {
  --width: 22rem;
}

textarea {
  resize: none !important;
}

:root {
  --header-height: 3.5rem;
  --footer-height: 7.0625rem;
  --header-buttons-width: 5.5rem;
  --logo-padding-right: 3.25rem;
  --slider-bar-color: var(--ion-color-primary);
  --slider-bar-opacity: .5;
  --slider-knob-color: var(--ion-color-primary);
  --slider-shadow: none;
  --slider-size: 1;
  --global-border-color: rgba(255, 255, 255, .38);
  --form-label-color: rgba(255, 255, 255, .6);
  --menu-icon-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), .54);
  --button-height: 2.25rem;
  --laptop-height: 45rem;
  --desktop-width: 25rem;
  --desktop-height: 50rem;
  --desktop-footer-height: 6.8575rem;
  --map-details-arrow-padding: 0rem;
  --trailer-map-inputs-translate-y-extra: 0rem;
  --trailer-map-inputs-in-progress-translate-y-extra: 0rem;
  --trailer-map-inputs-completed-translate-y-extra: 0rem;
  --trailer-map-driver-details-height: 16.5rem;
  --trailer-map-driver-details-translate-y: calc(var(--trailer-map-driver-details-height) - 2rem + .15rem);
  --driver-map-tow-details-height: 11rem;
  --driver-map-tow-details-translate-y: calc(var(--driver-map-tow-details-height) - 2rem + .15rem);
}
:root .ios {
  --header-height: 7rem;
  --header-buttons-width: 4.8755rem;
  --logo-padding-right: 3.909375rem;
  --slider-bar-color: var(--ion-color-light-contrast);
  --slider-bar-opacity: .1;
  --slider-knob-color: var(--ion-color-dark-contrast);
  --slider-shadow: 0 3px 1px rgb(0 0 0 / 10%), 0 4px 8px rgb(0 0 0 / 13%), 0 0 0 1px rgb(0 0 0 / 2%);
  --slider-size: 1.5;
  --menu-icon-color: var(--ion-text-color);
  --button-height: 3.099375rem;
  --map-details-arrow-padding: 1rem;
  --trailer-map-inputs-translate-y-extra: .25rem;
  --trailer-map-inputs-in-progress-translate-y-extra: -1rem;
  --trailer-map-inputs-completed-translate-y-extra: -1rem;
  --trailer-map-driver-details-height: calc(16.5rem + var(--map-details-arrow-padding));
  --trailer-map-driver-details-translate-y: calc(var(--trailer-map-driver-details-height) - 2rem + .15rem - var(--map-details-arrow-padding));
  --driver-map-tow-details-height: calc(11.5rem + var(--map-details-arrow-padding));
  --driver-map-tow-details-translate-y: calc(var(--driver-map-tow-details-height) - 2rem + .15rem - var(--map-details-arrow-padding));
}

@media screen and (min-width: 992px) {
  ion-app {
    width: var(--desktop-width);
    height: var(--laptop-height);
    margin: auto;
    box-shadow: 0 0 4rem rgba(0, 0, 0, 0.5);
  }
  ion-modal {
    --height: 100%;
  }
  .pac-container {
    width: calc(var(--desktop-width) - 4rem) !important;
    left: calc(50% - (var(--desktop-width) - 4rem) / 2) !important;
  }
}
@media screen and (min-width: 992px) and (min-height: 800px) {
  ion-app {
    height: var(--desktop-height);
  }
}
.btn-facebook {
  --background: #4a68ad;
}

.btn-google {
  --background: #DB4437;
}

.photo-upload .camera {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--global-border-color);
  color: var(--global-border-color);
  height: 15rem;
}
.photo-upload .camera i {
  font-size: 3rem;
}
.photo-upload input {
  position: absolute;
  width: calc(100% - 2rem);
  opacity: 0;
  height: 15rem;
  z-index: 1;
  cursor: pointer;
}

.photo {
  display: flex;
}
.photo img {
  border-radius: 4px;
  border: 1px solid var(--global-border-color);
}

.list-warning,
.list-error {
  font-size: 0.75rem;
  display: flex;
  align-items: center;
}
.list-warning .icon,
.list-error .icon {
  margin-right: 0.125rem;
  min-width: 0.75rem;
}

.list-warning {
  color: var(--ion-color-warning);
}

.list-error {
  color: var(--ion-color-danger);
}

#coupler-size-alert .alert-checkbox-group {
  border: none;
}
#coupler-size-alert .alert-checkbox-group .alert-checkbox {
  height: 4rem;
}
#coupler-size-alert .alert-checkbox-group .alert-checkbox .alert-checkbox-label {
  text-overflow: unset;
  white-space: normal;
}

.ellipsis {
  position: relative;
  width: 100%;
}
.ellipsis:before {
  content: "&nbsp;";
  visibility: hidden;
}
.ellipsis > span {
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notifications {
  margin-right: -0.5rem !important;
}
.notifications .count {
  position: absolute;
  margin: -1.25rem -1.25rem 0 0;
  background: var(--ion-toolbar-background, var(--ion-background-color));
  border: 1px solid var(--ion-toolbar-color, var(--ion-text-color));
  border-radius: 50%;
  width: 1.15rem;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.65rem;
}
.notifications.ios {
  margin-right: -0.25rem !important;
}
.notifications.ios .count {
  margin-top: -0.85rem;
}

.link {
  color: var(--ion-color-primary);
  text-decoration: underline;
  cursor: pointer;
}

.invisible {
  visibility: hidden;
}

.install.md {
  --max-width: 20rem;
}
.install.md .install-close-btn .alert-button-inner {
  min-width: 2.75rem;
  justify-content: center;
}
.install .alert-title {
  margin: 1rem 0;
}
.install .alert-message {
  max-height: 22rem;
  padding-top: 0.5rem;
}
.install .alert-message > img {
  display: flex;
  margin: 0 auto;
  border-radius: 0.5rem;
}
.install .alert-message ol {
  padding-left: 1rem;
  margin: 0;
}
.install .alert-message ol li {
  margin: 0.5rem 0;
}
.install .alert-message ol li .tap {
  display: flex;
  align-items: center;
  line-height: 2rem;
}
.install .alert-message ol li .tap i {
  font-size: 2rem;
  color: var(--ion-text-color);
}
.install .alert-message ol li .tap img {
  height: 2rem;
  width: 2rem;
  border-radius: 0.25rem;
  margin: 0 0.5rem;
}
.install .alert-message ol li .tap .android-icon {
  margin: 0 0.25rem;
  color: var(--ion-text-color);
}
.install .alert-message ol li .tap.ios i.share-icon {
  color: #4693ff;
}
.install.ios .message {
  display: flex;
  justify-content: center;
}

.flip {
  transform: rotate(180deg);
}

.map-instructions .pins {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}
.map-instructions .pins > div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.map-instructions .pins > div img {
  height: 2rem;
}
.map-instructions .drivers-icon {
  height: 1rem;
  margin: 0 0.15rem -0.15rem 0.15rem;
}
.map-instructions.ios .pins {
  width: 4.5rem;
  margin: 0 auto 1rem auto;
}

.success {
  color: var(--ion-color-success);
}

@media screen and (min-width: 412px) {
  .photo-upload input {
    width: calc(100% - 4rem);
  }
}
.map-icon {
  height: 2.5rem;
}
.map-icon.drop {
  opacity: 0;
  animation: drop 0.3s linear forwards 0.5s;
}
.map-icon.bounce {
  animation: bounce 0.8s ease-in-out infinite;
}

@keyframes drop {
  0% {
    transform: translateY(-12.5rem) scaleY(0.9);
  }
  5% {
    opacity: 0.7;
  }
  50% {
    transform: translateY(0) scaleY(1);
    opacity: 1;
  }
  65% {
    transform: translateY(-1rem) scaleY(0.9);
    opacity: 1;
  }
  75% {
    transform: translateY(-1.5rem) scaleY(0.9);
    opacity: 1;
  }
  100% {
    transform: translateY(0) scaleY(1);
    opacity: 1;
  }
}
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1rem);
  }
}