@import '../base/variables';

html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

main {
    margin: $main-margin;
    min-height: calc(100% - $main-margin * 2);
    display: flex;
    flex-direction: column;

    &.center {
        justify-content: center;
    }
}

section {
    ion-list {
        padding: 0 !important;
    }

    ion-item {
        --inner-padding-start: 0;
        --inner-padding-end: 0;
        --padding-start: 0;
        --padding-end: 0;
    }
}

p, .p {
    line-height: 1.5rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1rem var(--ion-background-color) inset !important;
    -webkit-text-fill-color: var(--ion-text-color) !important;
}

@media screen and (min-width: $sm-min-width) {
    main {
        margin: $main-margin-sm;
        min-height: calc(100% - $main-margin-sm * 2);
    }
}

@media screen and (orientation: landscape) {
    html.ios {
        body {
            width: 100vh;
            height: 100vw;
            transform-origin: 0 0;

            &.rotate-right {
                transform: rotate(90deg) translateY(-100%);
                margin-left: calc(100vw - 100vh);
            }

            &.rotate-left {
                transform: rotate(-90deg) translateX(-100%);
            }
        }

        ion-app {
            height: 100vw;
        }
    }
}
