@import '../base/variables';

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

:root {
	--ion-background-color: #253546;
	--ion-background-color-rgb: 37,53,70;

	--ion-text-color: #f2f2f2;
	--ion-text-color-rgb: 242,242,242;

	--ion-color-step-50: #2f3e4f;
	--ion-color-step-100: #3a4857;
	--ion-color-step-150: #445160;
	--ion-color-step-200: #4e5b68;
	--ion-color-step-250: #586471;
	--ion-color-step-300: #636e7a;
	--ion-color-step-350: #6d7782;
	--ion-color-step-400: #77818b;
	--ion-color-step-450: #818a93;
	--ion-color-step-500: #8c949c;
	--ion-color-step-550: #969da5;
	--ion-color-step-600: #a0a6ad;
	--ion-color-step-650: #aab0b6;
	--ion-color-step-700: #b4b9be;
	--ion-color-step-750: #bfc3c7;
	--ion-color-step-800: #c9ccd0;
	--ion-color-step-850: #d3d6d8;
	--ion-color-step-900: #dedfe1;
	--ion-color-step-950: #e8e9e9;

    --ion-color-primary: #6B9BCB;
	--ion-color-primary-rgb: 107,155,203;
	--ion-color-primary-contrast: var(--ion-text-color);
	--ion-color-primary-contrast-rgb: 0,0,0;
	--ion-color-primary-shade: #5e88b3;
	--ion-color-primary-tint: #7aa5d0;

	--ion-color-secondary: #7272D8;
	--ion-color-secondary-rgb: 114,114,216;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #6464be;
	--ion-color-secondary-tint: #8080dc;

	--ion-color-tertiary: #F2F9FF;
	--ion-color-tertiary-rgb: 242,249,255;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0,0,0;
	--ion-color-tertiary-shade: #d5dbe0;
	--ion-color-tertiary-tint: #f3faff;

	--ion-color-success: #a1d672;
	--ion-color-success-rgb: 161,214,114;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #8ebc64;
	--ion-color-success-tint: #aada80;

	--ion-color-warning: #cec92c;
	--ion-color-warning-rgb: 206,201,44;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #b5b127;
	--ion-color-warning-tint: #d3ce41;

	--ion-color-danger: #d17474;
	--ion-color-danger-rgb: 209,116,116;
	--ion-color-danger-contrast: #000000;
	--ion-color-danger-contrast-rgb: 0,0,0;
	--ion-color-danger-shade: #b86666;
	--ion-color-danger-tint: #d68282;

    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

	--ion-color-medium: #CDCDCD;
	--ion-color-medium-rgb: 205,205,205;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #b4b4b4;
	--ion-color-medium-tint: #d2d2d2;

	--ion-color-light: #f2f2f2;
	--ion-color-light-rgb: 242,242,242;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d5d5d5;
	--ion-color-light-tint: #f3f3f3;
}

// Overrides

ion-alert {
    z-index: 99999 !important;
    --max-width: 20.5rem !important;

    .alert-sub-title {
        line-height: normal !important;
    }

    .alert-button:disabled {
        cursor: default;
        opacity: .5;
        pointer-events: none;
    }

    .alert-message {
        ul {
            margin: 0;
            padding: 0 0 0 1rem;
            text-align: left;
        }

        p {
            &.align-left {
                text-align: left;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.ios {
        .alert-sub-title {
            color: var(--ion-text-color);
        }
    }
}

ion-button.button-outline {
    --border-width: 1px;
}

ion-datetime {
    --background-rgb: 58, 72, 87; // RGB values of --ion-color-step-100

    &.ios {
        --background: var(--ion-color-step-100);
        --background-rgb: var(--ion-background-color-rgb);
    }
}

.fab-button-disabled {
    opacity: .75;
}

@media screen and (min-width: $desktop-min-width) {
    .alert-wrapper {
        --width: calc(var(--desktop-width) - 4rem);
    }
}
