$sm-min-width: 412px;
$main-margin: 1rem;
$main-margin-sm: 2rem;
$camera-size: 15rem;
$map-animations-duration: 700ms;
$desktop-min-width: 992px; // Matches Ionic's "lg" breakpoint
$desktop-min-height: 800px;
$form-field-icon-suffix-width: 2.75rem;
$header-height: 3.5rem;
$header-height-ios: 7rem; // This should be 2.75rem but seems that in iOS devices it needs to add the top bar as well

:root {
    --header-height: 3.5rem;
    --footer-height: 7.0625rem; // Footer image (avg cause it changes depending on the width)

    --header-buttons-width: 5.5rem;

    --logo-padding-right: 3.25rem;

    --slider-bar-color: var(--ion-color-primary);
    --slider-bar-opacity: .5;
    --slider-knob-color: var(--ion-color-primary);
    --slider-shadow: none;
    --slider-size: 1;

    --global-border-color: rgba(255, 255, 255, .38);
    --form-label-color: rgba(255, 255, 255, .6);
    --menu-icon-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), .54);
    --button-height: 2.25rem;

    --laptop-height: 45rem;
    --desktop-width: 25rem;
    --desktop-height: 50rem;
    --desktop-footer-height: 6.8575rem;

    --map-details-arrow-padding: 0rem; // rem needed cause we are using it in a calc operation
    --trailer-map-inputs-translate-y-extra: 0rem; // rem needed cause we are using it in a calc operation
    --trailer-map-inputs-in-progress-translate-y-extra: 0rem; // rem needed cause we are using it in a calc operation
    --trailer-map-inputs-completed-translate-y-extra: 0rem; // rem needed cause we are using it in a calc operation
    --trailer-map-driver-details-height: 16.5rem;
    --trailer-map-driver-details-translate-y: calc(var(--trailer-map-driver-details-height) - 2rem + .15rem);

    --driver-map-tow-details-height: 11rem;
    --driver-map-tow-details-translate-y: calc(var(--driver-map-tow-details-height) - 2rem + .15rem);

    .ios {
        --header-height: 7rem; // This should be 2.75rem but seems that in iOS devices it needs to add the top bar as well

        --header-buttons-width: 4.8755rem;

        --logo-padding-right: 3.909375rem;

        --slider-bar-color: var(--ion-color-light-contrast);
        --slider-bar-opacity: .1;
        --slider-knob-color: var(--ion-color-dark-contrast);
        --slider-shadow: 0 3px 1px rgb(0 0 0 / 10%), 0 4px 8px rgb(0 0 0 / 13%), 0 0 0 1px rgb(0 0 0 / 2%);
        --slider-size: 1.5;

        --menu-icon-color: var(--ion-text-color);
        --button-height: 3.099375rem;

        --map-details-arrow-padding: 1rem;
        --trailer-map-inputs-translate-y-extra: .25rem;
        --trailer-map-inputs-in-progress-translate-y-extra: -1rem;
        --trailer-map-inputs-completed-translate-y-extra: -1rem;
        --trailer-map-driver-details-height: calc(16.5rem + var(--map-details-arrow-padding));
        --trailer-map-driver-details-translate-y: calc(var(--trailer-map-driver-details-height) - 2rem + .15rem - var(--map-details-arrow-padding));

        --driver-map-tow-details-height: calc(11.5rem + var(--map-details-arrow-padding));
        --driver-map-tow-details-translate-y: calc(var(--driver-map-tow-details-height) - 2rem + .15rem - var(--map-details-arrow-padding));
    }
}
