// Setup theme
@use '@angular/material' as mat;

@include mat.core();

$primary-palette: (
  50: #e8f2f8,
  100: #c6dff0,
  200: #a6cce6,
  300: #8ab8da,
  400: #78a9d2,
  500: #6B9BCB,
  600: #618dbe,
  700: #567cac,
  800: #4d6c9a,
  900: #3e4f78,
  contrast: (
    50: black,
    100: black,
    200: black,
    300: black,
    400: black,
    500: black,
    600: #f2f2f2,
    700: #f2f2f2,
    800: #f2f2f2,
    900: #f2f2f2
  )
);

$accent-palette: (
  50: #ebebfa,
  100: #cdcef2,
  200: #acaee9,
  300: #8a8de0,
  400: #7272d8,
  500: #5c57cf,
  600: #564ec4,
  700: #4d44b8,
  800: #4639ac,
  900: #3b2495,
  contrast: (
    50: black,
    100: black,
    200: black,
    300: black,
    400: #f2f2f2,
    500: #f2f2f2,
    600: #f2f2f2,
    700: #f2f2f2,
    800: #f2f2f2,
    900: #f2f2f2
  )
);

$warn-palette: (
  50: #fbebed,
  100: #f5ccd1,
  200: #e0999a,
  300: #d17474,
  400: #d95754,
  500: #de473b,
  600: #cf3f3a,
  700: #be3633,
  800: #b1302d,
  900: #a32521,
  contrast: (
    50: black,
    100: black,
    200: black,
    300: black,
    400: #f2f2f2,
    500: #f2f2f2,
    600: #f2f2f2,
    700: #f2f2f2,
    800: #f2f2f2,
    900: #f2f2f2
  )
);

$dnt-primary: mat.m2-define-palette($primary-palette);
$dnt-accent: mat.m2-define-palette($accent-palette, 400);
$dnt-warn: mat.m2-define-palette($warn-palette, 300);
$dnt-theme: mat.m2-define-dark-theme((
  color: (
    primary: $dnt-primary,
    accent: $dnt-accent,
    warn: $dnt-warn
  )
));
@include mat.all-component-themes($dnt-theme);

// Overrides
.mat-form-field.warning {
    .mat-form-field-outline-start,
    .mat-form-field-outline-end {
        border-color: var(--ion-color-warning) !important;
        border-width: 2px !important;
    }

    .mat-form-field-outline-gap {
        border-bottom-color: var(--ion-color-warning) !important;
        border-width: 2px !important;
    }

    .mat-form-field-label {
        color: var(--ion-color-warning);
    }
}

.mat-mdc-select-panel {
    background-color: var(--ion-color-step-100) !important;
}

.mat-mdc-option .mdc-list-item__primary-text {
    color: var(--ion-text-color);
}
